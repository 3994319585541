site-header {
  display: block;
  padding-block: var(--length-xl);
  padding-inline: var(--length-m);
  text-align: center;

  & header {
    container: header / inline-size;
    display: grid;
    gap: var(--length-s);
    grid:
      "role"
      "name"
      "svg"
      "skills";
    place-items: center;
  }

  & h1 {
    grid-area: name;
    margin-block: unset;
  }

  & h2 {
    font-family: inherit;
    font-size: inherit;
    font-weight: 650;
    grid-area: role;
    letter-spacing: 0.1em;
    margin-block: unset;
    text-transform: uppercase;
  }

  & svg {
    grid-area: svg;
  }

  & ul {
    all: unset;
    display: grid;
    grid-area: skills;

    & li {
      all: unset;
    }

    @container header (width <= 24em) {
      display: grid;
    }

    @container header (width > 24em) {
      display: flex;
      gap: var(--length-3xs) var(--length-l);
      place-content: center;

      & li:not(:last-child) {
        position: relative;

        &::before {
          content: "·";
          font-size: var(--font-size-l);
          font-weight: 700;
          inset-inline-end: -1.25rem;
          position: absolute;
        }
      }
    }
  }
}

highlighted-projects {
  background-color: var(--snow-100);
  border-color: var(--snow-90);
  border-style: solid;
  border-width: 1px 0;
  container: projects / inline-size;
  display: grid;
  gap: var(--length-xl);
  padding-block: var(--length-2xl);
  padding-inline: max(50% - 38ch, var(--length-m));
  place-items: center;
  position: relative;
  text-align: start;

  &::before {
    background: white;
    block-size: 2rem;
    border: 1px solid var(--snow-90);
    border-width: 0 1px 1px 0;
    content: "";
    inline-size: 2rem;
    inset-block-start: calc(-3rem - 0.25rem);
    position: absolute;
    transform: translateX(50%) rotate(45deg);
    transform-origin: -100% 50%;
    z-index: 1;
  }

  & h2 {
    display: none;
  }

  & article {
    display: grid;
    gap: var(--length-l);
    place-items: center;

    & > picture {
      grid-area: picture;
    }

    & > div {
      grid-area: content;

      & > h3 {
        margin-block-start: unset;
      }
    }

    @container projects (width <= 36em) {
      grid:
        "picture"
        "content";

      & picture {
        max-inline-size: min(80%, 22rem);
      }
    }

    @container projects (width > 36em) {
      &:nth-child(odd) {
        grid: "picture content";
        grid-template-columns: 2fr 3fr;
      }

      &:nth-child(even) {
        grid: "content picture";
        grid-template-columns: 3fr 2fr;
      }
    }
  }

  & img {
    border: 1px solid var(--snow-90);
    border-radius: 4px;
  }
}

about-me {
  display: grid;
  padding-block: var(--length-2xl);
  padding-inline: max(50% - 26ch, var(--length-m));
  place-items: center;
  text-align: center;

  & > h2 {
    margin-block-end: 0.28em;
    margin-block-start: unset;
  }

  & > p:last-of-type {
    margin-block-end: initial;
  }
}

hire-me {
  background-color: var(--snow-100);
  border-block-start: 1px solid var(--snow-90);
  display: grid;
  padding-block: var(--length-2xl);
  padding-inline: max(50% - 26ch, var(--length-m));
  place-items: center;
  position: relative;
  text-align: center;

  &::before {
    background: white;
    block-size: 2rem;
    border: 1px solid var(--snow-90);
    border-width: 0 1px 1px 0;
    content: "";
    inline-size: 2rem;
    inset-block-start: calc(-3rem - 0.25rem);
    position: absolute;
    transform: translateX(50%) rotate(45deg);
    transform-origin: -100% 50%;
    z-index: 1;
  }

  & > h3 {
    margin-block-end: 0.28em;
    margin-block-start: initial;
  }

  & > p:last-of-type {
    margin-block-end: initial;
  }
}

prominent-signpost {
  font-family: Lexend, "Lexend fallback", sans-serif;
  font-weight: 500;

  & > a {
    &:any-link {
      display: inline-flex;
      position: relative;
      text-decoration: none;

      &::after {
        background: currentcolor;
        block-size: 1.6rem;
        content: "";
        display: block;
        inline-size: 1.6rem;
        margin-inline-start: 0.5rem;
        mask-image: url("arrow-right.svg");
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        transition: transform ease-out 0.3s;
      }
    }

    &:hover {
      &::after {
        transform: translateX(0.25rem);
      }
    }
  }
}

digital-garden {
  display: block;
  padding-block-start: var(--length-4xl);
  padding-inline: max(50% - 26ch, var(--length-m));
  position: relative;

  & > svg {
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    line-height: 1;
    overflow: hidden;
    position: absolute;
    transform: rotate(180deg);
  }

  & > lazy-video {
    & > video {
      margin-inline: auto;
      max-inline-size: 70%;
    }

    &:first-of-type {
      & > video {
        translate: -1rem 1rem;
      }
    }
  }
}
