:root {
  --prism-scheme: light;

  /* Colors */
  --prism-foreground: #393a34;
  --prism-background: #fbfbfb;
  --prism-comment: #a0ada0;
  --prism-string: #b56959;
  --prism-literal: #2f8a89;
  --prism-number: #296aa3;
  --prism-keyword: #1c6b48;
  --prism-function: #6c7834;
  --prism-boolean: #1c6b48;
  --prism-constant: #a65e2b;
  --prism-deleted: #a14f55;
  --prism-class: #2993a3;
  --prism-builtin: #ab5959;
  --prism-property: #b58451;
  --prism-namespace: #b05a78;
  --prism-punctuation: #8e8f8b;
  --prism-decorator: #bd8f8f;
  --prism-regex: #ab5e3f;
  --prism-json-property: #698c96;
  --prism-operator: var(--prism-punctuation);
  --prism-variable: var(--prism-literal);
  --prism-symbol: var(--prism-literal);
  --prism-interpolation: var(--prism-literal);
  --prism-selector: var(--prism-keyword);
  --prism-keyword-control: var(--prism-keyword);
  --prism-inline-background: var(--prism-background);

  /* Token Styles */
  --prism-comment-style: italic;
  --prism-url-decoration: underline;

  /* Extension */
  --prism-line-number: #a5a5a5;
  --prism-line-number-gutter: #333;
  --prism-line-highlight-background: #eee;
  --prism-selection-background: #ddd;
  --prism-marker-color: var(--prism-foreground);
  --prism-marker-opacity: 0.4;
  --prism-marker-font-size: 0.8em;

  /* Font */
  --prism-font-size: 1em;
  --prism-line-height: 1.5em;
  --prism-font-family: monospace;
  --prism-inline-font-size: var(--prism-font-size);
  --prism-block-font-size: var(--prism-font-size);

  /* Sizing */
  --prism-tab-size: 2;
  --prism-block-padding-x: 1em;
  --prism-block-padding-y: 1em;
  --prism-block-margin-x: 0;
  --prism-block-margin-y: 0.5em;
  --prism-block-radius: 4px;
  --prism-inline-padding-x: 0.3em;
  --prism-inline-padding-y: 0.1em;
  --prism-inline-radius: 4px;
}

pre[class*="language-"],
code[class*="language-"] {
  color: var(--prism-foreground) !important;
  direction: ltr;
  font-family: var(--prism-font-family);
  font-size: var(--prism-font-size);
  hyphens: none;
  line-height: var(--prism-line-height);
  tab-size: var(--prism-tab-size);
  text-align: start;
  white-space: pre;
  word-break: normal;
  word-spacing: normal;
}

/* Code blocks */
pre:has(> code[class*="language-"]) {
  background: var(--prism-background);
  border-radius: var(--prism-block-radius);
  font-size: var(--prism-block-font-size);
  margin-block: var(--prism-block-margin-y);
  margin-inline: var(--prism-block-margin-x);
  overflow: auto;
  padding-block: var(--prism-block-padding-y);
  padding-inline: var(--prism-block-padding-x);
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  background: var(--prism-inline-background);
  border-radius: var(--prism-inline-radius);
  font-size: var(--prism-inline-font-size);
  padding-block: var(--prism-inline-padding-y);
  padding-inline: var(--prism-inline-padding-x);
}

/* Selection */
code[class*="language-"]::selection {
  background: var(--prism-selection-background);
}

/* Tokens */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: var(--prism-comment);
  font-style: var(--prism-comment-style);
}

.token.namespace {
  color: var(--prism-namespace);
}

.token.interpolation {
  color: var(--prism-interpolation);
}

.token.string {
  color: var(--prism-string);
}

.token.punctuation {
  color: var(--prism-punctuation);
}

.token.operator {
  color: var(--prism-operator);
}

.token.keyword.module,
.token.keyword.control-flow {
  color: var(--prism-keyword-control);
}

.token.url,
.token.symbol,
.token.inserted {
  color: var(--prism-symbol);
}

.token.constant {
  color: var(--prism-constant);
}

.token.string.url {
  text-decoration: var(--prism-url-decoration);
}

.token.boolean,
.language-json .token.boolean {
  color: var(--prism-boolean);
}

.token.number,
.language-json .token.number {
  color: var(--prism-number);
}

.token.variable {
  color: var(--prism-variable);
}

.token.keyword {
  color: var(--prism-keyword);
}

.token.atrule,
.token.attr-value,
.token.selector {
  color: var(--prism-selector);
}

.token.function {
  color: var(--prism-function);
}

.token.deleted {
  color: var(--prism-deleted);
}

.token.important,
.token.bold {
  font-weight: 700;
}

.token.italic {
  font-style: italic;
}

.token.class-name {
  color: var(--prism-class);
}

.token.tag,
.token.builtin {
  color: var(--prism-builtin);
}

.token.attr-name,
.token.property,
.token.entity {
  color: var(--prism-property);
}

.language-json .token.property {
  color: var(--prism-json-property);
}

.token.regex {
  color: var(--prism-regex);
}

.token.decorator,
.token.annotation {
  color: var(--prism-decorator);
}
