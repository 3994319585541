@layer defaults {
  @font-face {
    font-display: swap;
    font-family: Lexend;
    font-style: normal;
    font-weight: 500 700;
    src: url("lexend.1f0de229.woff2") format("woff2");
    unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    ascent-override: 100%;
    descent-override: 25%;
    font-family: Lexend fallback;
    line-gap-override: 0%;
    src: local(Arial);
  }

  @font-face {
    font-display: swap;
    font-family: Figtree;
    font-style: italic;
    font-weight: 400 700;
    src: url("figtree-italic.68fb5d8a.woff2") format("woff2");
    unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-display: swap;
    font-family: Figtree;
    font-style: normal;
    font-weight: 400 700;
    src: url("figtree.63507bce.woff2") format("woff2");
    unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    ascent-override: 95%;
    descent-override: 25%;
    font-family: Figtree fallback;
    line-gap-override: 0%;
    src: local(Arial);
  }

  :root {
    --blue-50: #2266a4;
    --burnt-orange: #ffa383;
    --mustard-yellow: #f5b002;
    --olive-green: #6d8e1f;
    --pink-50: #b30169;
    --purple-50: #5d57a4;
    --rustic-red: #fe6742;
    --slate-40: #3c4958;
    --snow-100: #f9f8f9;
    --snow-90: #dfdddf;
    --font-size-xs: clamp(.6914rem, .6814rem + .0498vi, .72rem);
    --font-size-s: clamp(.7778rem, .7353rem + .2126vi, .9rem);
    --font-size-m: clamp(.875rem, .788rem + .4348vi, 1.125rem);
    --font-size-l: clamp(.9844rem, .8376rem + .7337vi, 1.4063rem);
    --font-size-xl: clamp(1.1074rem, .8812rem + 1.1311vi, 1.7578rem);
    --font-size-2xl: clamp(1.2458rem, .9149rem + 1.6546vi, 2.1973rem);
    --font-size-3xl: clamp(1.4016rem, .9338rem + 2.3391vi, 2.7466rem);
    --font-size-4xl: clamp(1.5768rem, .9311rem + 3.2286vi, 3.4332rem);
    --font-size-5xl: clamp(1.7739rem, .8982rem + 4.3785vi, 4.2915rem);
    --length-3xs: clamp(.25rem, .2283rem + .1087vi, .3125rem);
    --length-2xs: clamp(.4375rem, .394rem + .2174vi, .5625rem);
    --length-xs: clamp(.6875rem, .6223rem + .3261vi, .875rem);
    --length-s: clamp(.875rem, .788rem + .4348vi, 1.125rem);
    --length-m: clamp(1.3125rem, 1.1821rem + .6522vi, 1.6875rem);
    --length-l: clamp(1.75rem, 1.5761rem + .8696vi, 2.25rem);
    --length-xl: clamp(2.625rem, 2.3641rem + 1.3043vi, 3.375rem);
    --length-2xl: clamp(3.5rem, 3.1522rem + 1.7391vi, 4.5rem);
    --length-3xl: clamp(5.25rem, 4.7283rem + 2.6087vi, 6.75rem);
    --length-4xl: clamp(7rem, 6.3043rem + 3.4783vi, 9rem);
    --length-l-xl: clamp(1.75rem, 1.1848rem + 2.8261vi, 3.375rem);
    --length-6-8: clamp(.375rem, .3315rem + .2174vi, .5rem);
  }

  @supports (color: lab(0% 0 0)) {
    :root {
      --blue-50: lab(41.4353% -4.04441 -40.3761);
      --burnt-orange: lab(75.9502% 32.4275 31.481);
      --mustard-yellow: lab(76.7917% 17.9773 78.8842);
      --olive-green: lab(54.8611% -23.5266 50.4524);
      --pink-50: lab(39.3183% 65.1186 -5.8995);
      --purple-50: lab(40.4664% 18.1547 -41.5145);
      --rustic-red: lab(63.5342% 57.2187 50.8689);
      --slate-40: lab(30.3447% -2.69409 -10.4528);
      --snow-100: lab(97.6462% .737488 -.468421);
      --snow-90: lab(88.3662% .737429 -.468373);
    }
  }

  * {
    line-height: calc(var(--length-6-8)  + 2ex);
  }

  :focus {
    outline: none;
    scroll-padding-block-end: 8vb;
  }

  :focus-visible {
    outline-offset: 2px;
    outline: 2px solid #000;
    box-shadow: 0 0 0 1px #fff;
  }

  :where([id]) {
    scroll-margin-block-start: var(--length-xl);
  }

  html {
    scrollbar-gutter: stable;
  }

  @media (prefers-reduced-motion: no-preference) {
    html {
      scroll-behavior: smooth;
    }
  }

  body {
    background-image: linear-gradient(to right, var(--burnt-orange) 25%, var(--olive-green) 25% 50%, var(--mustard-yellow) 50% 75%, var(--rustic-red) 75%), linear-gradient(to right, var(--burnt-orange) 25%, var(--olive-green) 25% 50%, var(--mustard-yellow) 50% 75%, var(--rustic-red) 75%);
    color: var(--slate-40);
    font-kerning: normal;
    font-optical-sizing: auto;
    font-family: Figtree, Figtree fallback, sans-serif;
    font-size: var(--font-size-m);
    font-variant-ligatures: common-ligatures no-discretionary-ligatures no-historical-ligatures contextual;
    font-variant-numeric: oldstyle-nums proportional-nums;
    hyphenate-limit-chars: 7 4 3;
    hyphenate-limit-last: always;
    hyphenate-limit-lines: 2;
    hyphenate-limit-zone: 8%;
    -webkit-hyphens: auto;
    hyphens: auto;
    -webkit-text-decoration-skip-ink: auto;
    text-decoration-skip-ink: auto;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    text-wrap: pretty;
    background-position: top, bottom;
    background-repeat: no-repeat;
    background-size: 100% .25rem, 100% .25rem, 100%;
    margin: 0;
    padding-block: .25rem;
    overflow: auto;
  }

  h1, h2, h3, h4 {
    font-variant-numeric: lining-nums;
    -webkit-hyphens: manual;
    hyphens: manual;
    letter-spacing: -.02em;
    font-family: Lexend, Lexend fallback, sans-serif;
    font-weight: 650;
  }

  h1 {
    font-size: var(--font-size-5xl);
    margin-block-end: .28em;
  }

  h2 {
    font-size: var(--font-size-3xl);
    margin-block: 1em .75em;
  }

  h3 {
    font-size: var(--font-size-xl);
    margin-block: 1.25em .83em;
  }

  h4 {
    font-size: var(--font-size-l);
    margin-block: 1.5em 1em;
  }

  :is(code, pre) {
    max-inline-size: 100%;
    overflow-y: auto;
  }

  a:any-link {
    cursor: pointer;
    text-underline-offset: .15em;
    text-decoration-thickness: max(.08em, 1px);
  }

  a:link {
    color: var(--blue-50);
  }

  a:visited {
    color: var(--purple-50);
  }

  a:active {
    color: var(--pink-50);
  }

  a:hover {
    text-decoration: none;
  }

  :is(img, video) {
    block-size: auto;
    max-inline-size: 100%;
    display: block;
  }

  svg {
    max-inline-size: 100%;
    display: block;
  }

  input, button, textarea, select {
    font-family: inherit;
    font-size: inherit;
  }

  td, math, time[datetime*=":"] {
    font-variant-numeric: tabular-nums lining-nums slashed-zero;
  }
}

@layer components {
  site-header {
    padding-block: var(--length-xl);
    padding-inline: var(--length-m);
    text-align: center;
    display: block;
  }

  site-header header {
    gap: var(--length-s);
    grid: "role"
          "name"
          "svg"
          "skills";
    place-items: center;
    display: grid;
    container: header / inline-size;
  }

  site-header h1 {
    margin-block: unset;
    grid-area: name;
  }

  site-header h2 {
    font-family: inherit;
    font-size: inherit;
    letter-spacing: .1em;
    margin-block: unset;
    text-transform: uppercase;
    grid-area: role;
    font-weight: 650;
  }

  site-header svg {
    grid-area: svg;
  }

  site-header ul {
    all: unset;
    grid-area: skills;
    display: grid;
  }

  site-header ul li {
    all: unset;
  }

  @container header (width <= 24em) {
    site-header ul {
      display: grid;
    }
  }

  @container header (width > 24em) {
    site-header ul {
      gap: var(--length-3xs) var(--length-l);
      place-content: center;
      display: flex;
    }

    site-header ul li:not(:last-child) {
      position: relative;
    }

    site-header ul li:not(:last-child):before {
      content: "·";
      font-size: var(--font-size-l);
      font-weight: 700;
      position: absolute;
      inset-inline-end: -1.25rem;
    }
  }

  highlighted-projects {
    background-color: var(--snow-100);
    border-color: var(--snow-90);
    gap: var(--length-xl);
    padding-block: var(--length-2xl);
    padding-inline: max(50% - 38ch, var(--length-m));
    text-align: start;
    border-style: solid;
    border-width: 1px 0;
    place-items: center;
    display: grid;
    position: relative;
    container: projects / inline-size;
  }

  highlighted-projects:before {
    border: 1px solid var(--snow-90);
    content: "";
    transform-origin: -100%;
    z-index: 1;
    background: #fff;
    border-width: 0 1px 1px 0;
    block-size: 2rem;
    inline-size: 2rem;
    position: absolute;
    inset-block-start: -3.25rem;
    transform: translateX(50%)rotate(45deg);
  }

  highlighted-projects h2 {
    display: none;
  }

  highlighted-projects article {
    gap: var(--length-l);
    place-items: center;
    display: grid;
  }

  highlighted-projects article > picture {
    grid-area: picture;
  }

  highlighted-projects article > div {
    grid-area: content;
  }

  highlighted-projects article > div > h3 {
    margin-block-start: unset;
  }

  @container projects (width <= 36em) {
    highlighted-projects article {
      grid: "picture"
            "content";
    }

    highlighted-projects article picture {
      max-inline-size: min(80%, 22rem);
    }
  }

  @container projects (width > 36em) {
    highlighted-projects article:nth-child(odd) {
      grid: "picture content"
      / 2fr 3fr;
    }

    highlighted-projects article:nth-child(2n) {
      grid: "content picture"
      / 3fr 2fr;
    }
  }

  highlighted-projects img {
    border: 1px solid var(--snow-90);
    border-radius: 4px;
  }

  about-me {
    padding-block: var(--length-2xl);
    padding-inline: max(50% - 26ch, var(--length-m));
    text-align: center;
    place-items: center;
    display: grid;
  }

  about-me > h2 {
    margin-block-start: unset;
    margin-block-end: .28em;
  }

  about-me > p:last-of-type {
    margin-block-end: initial;
  }

  hire-me {
    background-color: var(--snow-100);
    border-block-start: 1px solid var(--snow-90);
    padding-block: var(--length-2xl);
    padding-inline: max(50% - 26ch, var(--length-m));
    text-align: center;
    place-items: center;
    display: grid;
    position: relative;
  }

  hire-me:before {
    border: 1px solid var(--snow-90);
    content: "";
    transform-origin: -100%;
    z-index: 1;
    background: #fff;
    border-width: 0 1px 1px 0;
    block-size: 2rem;
    inline-size: 2rem;
    position: absolute;
    inset-block-start: -3.25rem;
    transform: translateX(50%)rotate(45deg);
  }

  hire-me > h3 {
    margin-block-start: initial;
    margin-block-end: .28em;
  }

  hire-me > p:last-of-type {
    margin-block-end: initial;
  }

  prominent-signpost {
    font-family: Lexend, Lexend fallback, sans-serif;
    font-weight: 500;
  }

  prominent-signpost > a:any-link {
    text-decoration: none;
    display: inline-flex;
    position: relative;
  }

  prominent-signpost > a:any-link:after {
    content: "";
    background: currentColor;
    block-size: 1.6rem;
    inline-size: 1.6rem;
    margin-inline-start: .5rem;
    transition: transform .3s ease-out;
    display: block;
    -webkit-mask-image: url("arrow-right.83310679.svg");
    mask-image: url("arrow-right.83310679.svg");
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }

  prominent-signpost > a:hover:after {
    transform: translateX(.25rem);
  }

  digital-garden {
    padding-block-start: var(--length-4xl);
    padding-inline: max(50% - 26ch, var(--length-m));
    display: block;
    position: relative;
  }

  digital-garden > svg {
    inline-size: 100%;
    line-height: 1;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    overflow: hidden;
    transform: rotate(180deg);
  }

  digital-garden > lazy-video > video {
    max-inline-size: 70%;
    margin-inline: auto;
  }

  digital-garden > lazy-video:first-of-type > video {
    translate: -1rem 1rem;
  }
}

@layer vendor {
  :root {
    --prism-scheme: light;
    --prism-foreground: #393a34;
    --prism-background: #fbfbfb;
    --prism-comment: #a0ada0;
    --prism-string: #b56959;
    --prism-literal: #2f8a89;
    --prism-number: #296aa3;
    --prism-keyword: #1c6b48;
    --prism-function: #6c7834;
    --prism-boolean: #1c6b48;
    --prism-constant: #a65e2b;
    --prism-deleted: #a14f55;
    --prism-class: #2993a3;
    --prism-builtin: #ab5959;
    --prism-property: #b58451;
    --prism-namespace: #b05a78;
    --prism-punctuation: #8e8f8b;
    --prism-decorator: #bd8f8f;
    --prism-regex: #ab5e3f;
    --prism-json-property: #698c96;
    --prism-operator: var(--prism-punctuation);
    --prism-variable: var(--prism-literal);
    --prism-symbol: var(--prism-literal);
    --prism-interpolation: var(--prism-literal);
    --prism-selector: var(--prism-keyword);
    --prism-keyword-control: var(--prism-keyword);
    --prism-inline-background: var(--prism-background);
    --prism-comment-style: italic;
    --prism-url-decoration: underline;
    --prism-line-number: #a5a5a5;
    --prism-line-number-gutter: #333;
    --prism-line-highlight-background: #eee;
    --prism-selection-background: #ddd;
    --prism-marker-color: var(--prism-foreground);
    --prism-marker-opacity: .4;
    --prism-marker-font-size: .8em;
    --prism-font-size: 1em;
    --prism-line-height: 1.5em;
    --prism-font-family: monospace;
    --prism-inline-font-size: var(--prism-font-size);
    --prism-block-font-size: var(--prism-font-size);
    --prism-tab-size: 2;
    --prism-block-padding-x: 1em;
    --prism-block-padding-y: 1em;
    --prism-block-margin-x: 0;
    --prism-block-margin-y: .5em;
    --prism-block-radius: 4px;
    --prism-inline-padding-x: .3em;
    --prism-inline-padding-y: .1em;
    --prism-inline-radius: 4px;
  }

  pre[class*="language-"], code[class*="language-"] {
    font-family: var(--prism-font-family);
    font-size: var(--prism-font-size);
    -webkit-hyphens: none;
    hyphens: none;
    line-height: var(--prism-line-height);
    tab-size: var(--prism-tab-size);
    text-align: start;
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    direction: ltr;
    color: var(--prism-foreground) !important;
  }

  pre:has( > code[class*="language-"]) {
    background: var(--prism-background);
    border-radius: var(--prism-block-radius);
    font-size: var(--prism-block-font-size);
    margin-block: var(--prism-block-margin-y);
    margin-inline: var(--prism-block-margin-x);
    padding-block: var(--prism-block-padding-y);
    padding-inline: var(--prism-block-padding-x);
    overflow: auto;
  }

  :not(pre) > code[class*="language-"] {
    background: var(--prism-inline-background);
    border-radius: var(--prism-inline-radius);
    font-size: var(--prism-inline-font-size);
    padding-block: var(--prism-inline-padding-y);
    padding-inline: var(--prism-inline-padding-x);
  }

  code[class*="language-"]::selection {
    background: var(--prism-selection-background);
  }

  .token.comment, .token.prolog, .token.doctype, .token.cdata {
    color: var(--prism-comment);
    font-style: var(--prism-comment-style);
  }

  .token.namespace {
    color: var(--prism-namespace);
  }

  .token.interpolation {
    color: var(--prism-interpolation);
  }

  .token.string {
    color: var(--prism-string);
  }

  .token.punctuation {
    color: var(--prism-punctuation);
  }

  .token.operator {
    color: var(--prism-operator);
  }

  .token.keyword.module, .token.keyword.control-flow {
    color: var(--prism-keyword-control);
  }

  .token.url, .token.symbol, .token.inserted {
    color: var(--prism-symbol);
  }

  .token.constant {
    color: var(--prism-constant);
  }

  .token.string.url {
    -webkit-text-decoration: var(--prism-url-decoration);
    text-decoration: var(--prism-url-decoration);
  }

  .token.boolean, .language-json .token.boolean {
    color: var(--prism-boolean);
  }

  .token.number, .language-json .token.number {
    color: var(--prism-number);
  }

  .token.variable {
    color: var(--prism-variable);
  }

  .token.keyword {
    color: var(--prism-keyword);
  }

  .token.atrule, .token.attr-value, .token.selector {
    color: var(--prism-selector);
  }

  .token.function {
    color: var(--prism-function);
  }

  .token.deleted {
    color: var(--prism-deleted);
  }

  .token.important, .token.bold {
    font-weight: 700;
  }

  .token.italic {
    font-style: italic;
  }

  .token.class-name {
    color: var(--prism-class);
  }

  .token.tag, .token.builtin {
    color: var(--prism-builtin);
  }

  .token.attr-name, .token.property, .token.entity {
    color: var(--prism-property);
  }

  .language-json .token.property {
    color: var(--prism-json-property);
  }

  .token.regex {
    color: var(--prism-regex);
  }

  .token.decorator, .token.annotation {
    color: var(--prism-decorator);
  }
}

@layer overrides {
  :root {
    --prism-background: var(--snow-100);
    --prism-comment: #697569;
    --prism-font-family: monospace;
    --prism-font-size: var(--font-size-s);
    --prism-json-property: #557881;
    --prism-property: #966634;
    --prism-string: #a4594d;
  }

  @supports (color: lab(0% 0 0)) {
    :root {
      --prism-comment: lab(48.1074% -6.56667 5.11869);
      --prism-json-property: lab(48.086% -11.1519 -9.04588);
      --prism-property: lab(47.5604% 16.1789 35.8718);
      --prism-string: lab(46.8929% 30.6318 21.1779);
    }
  }
}
/*# sourceMappingURL=index.b3c9b25b.css.map */
