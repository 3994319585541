/* Fonts */

@font-face {
  font-display: swap;
  font-family: Lexend;
  font-style: normal;
  font-weight: 500 700;
  src: url("lexend.woff2") format("woff2");
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  ascent-override: 100%;
  descent-override: 25%;
  font-family: "Lexend fallback";
  line-gap-override: 0%;
  src: local("Arial");
}

@font-face {
  font-display: swap;
  font-family: Figtree;
  font-style: italic;
  font-weight: 400 700;
  src: url("figtree-italic.woff2") format("woff2");
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: Figtree;
  font-style: normal;
  font-weight: 400 700;
  src: url("figtree.woff2") format("woff2");
  unicode-range:
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  ascent-override: 95%;
  descent-override: 25%;
  font-family: "Figtree fallback";
  line-gap-override: 0%;
  src: local("Arial");
}

/* Main root */

:root {
  /* Colors */
  --blue-50: oklch(50% 0.12 250deg);
  --burnt-orange: oklch(80% 0.118 40deg);
  --mustard-yellow: oklch(80% 0.1654 80deg);
  --olive-green: oklch(60% 0.138 125deg);
  --pink-50: oklch(50% 0.205 355deg);
  --purple-50: oklch(50% 0.12 285deg);
  --rustic-red: oklch(70% 0.192 35deg);
  --slate-40: oklch(40% 0.03 250deg);
  --snow-100: oklch(98% 0.0025 330deg);
  --snow-90: oklch(90% 0.0025 330deg);

  /* Font sizes */

  /* @link https://utopia.fyi/type/calculator?c=320,14,1.125,1240,18,1.25,6,1,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  --font-size-xs: clamp(0.6914rem, 0.6814rem + 0.0498vi, 0.72rem);
  --font-size-s: clamp(0.7778rem, 0.7353rem + 0.2126vi, 0.9rem);
  --font-size-m: clamp(0.875rem, 0.788rem + 0.4348vi, 1.125rem);
  --font-size-l: clamp(0.9844rem, 0.8376rem + 0.7337vi, 1.4063rem);
  --font-size-xl: clamp(1.1074rem, 0.8812rem + 1.1311vi, 1.7578rem);
  --font-size-2xl: clamp(1.2458rem, 0.9149rem + 1.6546vi, 2.1973rem);
  --font-size-3xl: clamp(1.4016rem, 0.9338rem + 2.3391vi, 2.7466rem);
  --font-size-4xl: clamp(1.5768rem, 0.9311rem + 3.2286vi, 3.4332rem);
  --font-size-5xl: clamp(1.7739rem, 0.8982rem + 4.3785vi, 4.2915rem);

  /* Lengths */

  /* @link https://utopia.fyi/space/calculator?c=320,14,1.125,1240,18,1.25,6,1,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  --length-3xs: clamp(0.25rem, 0.2283rem + 0.1087vi, 0.3125rem);
  --length-2xs: clamp(0.4375rem, 0.394rem + 0.2174vi, 0.5625rem);
  --length-xs: clamp(0.6875rem, 0.6223rem + 0.3261vi, 0.875rem);
  --length-s: clamp(0.875rem, 0.788rem + 0.4348vi, 1.125rem);
  --length-m: clamp(1.3125rem, 1.1821rem + 0.6522vi, 1.6875rem);
  --length-l: clamp(1.75rem, 1.5761rem + 0.8696vi, 2.25rem);
  --length-xl: clamp(2.625rem, 2.3641rem + 1.3043vi, 3.375rem);
  --length-2xl: clamp(3.5rem, 3.1522rem + 1.7391vi, 4.5rem);
  --length-3xl: clamp(5.25rem, 4.7283rem + 2.6087vi, 6.75rem);
  --length-4xl: clamp(7rem, 6.3043rem + 3.4783vi, 9rem);
  --length-l-xl: clamp(1.75rem, 1.1848rem + 2.8261vi, 3.375rem);
  --length-6-8: clamp(0.375rem, 0.3315rem + 0.2174vi, 0.5rem);
}

/* Universal */

* {
  line-height: calc(var(--length-6-8) + 2ex);
}

/* Pseudos */

/* The oreo focus - https://www.erikkroes.nl/blog/the-universal-focus-state/ */
:focus {
  outline: none;
  scroll-padding-block-end: 8vb;
}

:focus-visible {
  box-shadow: 0 0 0 1px white;
  outline: 2px solid black;
  outline-offset: 2px;
}

/* Attributes */

/* Leave enough scroll spacing for anchors */
:where([id]) {
  scroll-margin-block-start: var(--length-xl);
}

/* Main root */

html {
  scrollbar-gutter: stable;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

/* Sectioning root */

body {
  background-image:
    linear-gradient(
      to right,
      var(--burnt-orange) 25%,
      var(--olive-green) 25% 50%,
      var(--mustard-yellow) 50% 75%,
      var(--rustic-red) 75%
    ),
    linear-gradient(
      to right,
      var(--burnt-orange) 25%,
      var(--olive-green) 25% 50%,
      var(--mustard-yellow) 50% 75%,
      var(--rustic-red) 75%
    );
  background-position: top, bottom;
  background-repeat: no-repeat;
  background-size:
    100% 0.25rem,
    100% 0.25rem,
    100%;
  color: var(--slate-40);
  font-family: Figtree, "Figtree fallback", sans-serif;
  font-kerning: normal;
  font-optical-sizing: auto;
  font-size: var(--font-size-m);
  font-variant-ligatures: common-ligatures no-discretionary-ligatures
    no-historical-ligatures contextual;
  font-variant-numeric: oldstyle-nums proportional-nums;
  hyphenate-limit-chars: 7 4 3;
  hyphenate-limit-last: always;
  hyphenate-limit-lines: 2;
  hyphenate-limit-zone: 8%;
  hyphens: auto;
  margin: 0;
  overflow: auto;
  padding-block: 0.25rem;
  text-decoration-skip-ink: auto;
  text-size-adjust: none;
  text-wrap: pretty;
}

/* Content sectioning */

h1,
h2,
h3,
h4 {
  font-family: Lexend, "Lexend fallback", sans-serif;
  font-variant-numeric: lining-nums;
  font-weight: 650;
  hyphens: manual;
  letter-spacing: -0.02em;
}

h1 {
  font-size: var(--font-size-5xl);
  margin-block-end: 0.28em;
}

h2 {
  font-size: var(--font-size-3xl);
  margin-block: 1em 0.75em;
}

h3 {
  font-size: var(--font-size-xl);
  margin-block: 1.25em 0.83em;
}

h4 {
  font-size: var(--font-size-l);
  margin-block: 1.5em 1em;
}

/* Text content */

:is(code, pre) {
  max-inline-size: 100%;
  overflow-y: auto;
}

/* Inline text semantics */

a {
  &:any-link {
    cursor: pointer;
    text-decoration-thickness: max(0.08em, 1px);
    text-underline-offset: 0.15em;
  }

  &:link {
    color: var(--blue-50);
  }

  &:visited {
    color: var(--purple-50);
  }

  &:active {
    color: var(--pink-50);
  }

  &:hover {
    text-decoration: none;
  }
}

/* Image and multimedia */

:is(img, video) {
  block-size: auto;
  display: block;
  max-inline-size: 100%;
}

/* SVG and math */

svg {
  display: block;
  max-inline-size: 100%;
}

/* Forms */

input,
button,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
}

/* Tables, times and maths */

td,
math,
time[datetime*=":"] {
  font-variant-numeric: tabular-nums lining-nums slashed-zero;
}
